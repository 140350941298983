import * as React from "react"
import { Box, Heading, Text, Tag } from "@chakra-ui/react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import { VStack, useColorModeValue } from "@chakra-ui/react"

const BlogList = () => {

    const color = useColorModeValue('black', 'white')
    const invertColor = useColorModeValue('white', 'black')

    const data = useStaticQuery(graphql`
    query {
        allMdx(sort: {fields: frontmatter___date, order: DESC}) {
          nodes {
            frontmatter {
              title
              slug
              date(formatString: "DD/MM/YYYY")
              excerpt
              tags
            }
          }
        }
      }
    `)
    return (
        <Box>
            {
                data.allMdx.nodes.map(node => (
                    <Link to={node.frontmatter.slug}>
                        <Box key={node.frontmatter.title} marginTop={4} marginBottom={8}>
                            <VStack
                                spacing={1}
                                align='stretch'
                            >
                                <Box>
                                    <Heading>{node.frontmatter.title}</Heading>
                                </Box>
                                <Box>
                                    <Text fontStyle={"italic"}>{node.frontmatter.date}</Text>
                                </Box>
                                <Box>
                                    <Text paddingBottom="2">{node.frontmatter.excerpt}</Text>
                                </Box>
                                <Box>
                                    {node.frontmatter.tags.split(",").map(tag =>
                                        (<Tag mx={1} backgroundColor={invertColor} borderBlock={color} border='1px' fontSize={"0.75em"}>{tag}</Tag>))}
                                </Box>
                            </VStack>
                        </Box>
                    </Link>
                ))
            }
        </Box>
    )
}


export default BlogList

