import * as React from "react"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import { Box } from "@chakra-ui/react"
import BlogList from "../components/blogList"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const IndexPage = () => {
  return (
    <Layout pageTitle={"Blog"}>
      <Box width={{ base: "sm" }}>
      </Box>
      <Box width={{ base: "xs", sm:"sm", md:"xl", lg: "4xl" }}>
        <BlogList/>
      </Box>
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl, twitterUsername, favicon} = useSiteMetadata()
  return (<SEO>
     <script type="application/ld+json">
          {`{
            "@context": "http://schema.org",
            "@type": "Blog",
            "name": "${defaultTitle}",
            "url": "${siteUrl}",
            "description": "${defaultDescription}",
            "publisher": {
              "@type": "Person",
              "name": "AS"
            }
          }`}
      </script>
  </SEO>
)
}

